<template>
    <div>
        <load-screen :is-loading="isLoading"></load-screen>
        <page-header :page-title="this.t('DASHBOARD.PAGE_TITLE')"></page-header>
        <div class="main-container">
            <!--         <div class="row gutters">-->
            <!--            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">-->
            <!--               <div class="info-stats4">-->
            <!--                  <div class="info-icon">-->
            <!--                     <i class="icon-users"></i>-->
            <!--                  </div>-->
            <!--                  <div class="sale-num">-->
            <!--                     <h3>{{ totalClients }}</h3>-->
            <!--                     <p>Pessoas</p>-->
            <!--                  </div>-->
            <!--               </div>-->
            <!--            </div>-->
            <!--            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">-->
            <!--               <div class="info-stats4">-->
            <!--                  <div class="info-icon">-->
            <!--                     <i class="icon-shopping-cart1"></i>-->
            <!--                  </div>-->
            <!--                  <div class="sale-num">-->
            <!--                     <h3>2500</h3>-->
            <!--                     <p>Vendas</p>-->
            <!--                  </div>-->
            <!--               </div>-->
            <!--            </div>-->
            <!--            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">-->
            <!--               <div class="info-stats4">-->
            <!--                  <div class="info-icon">-->
            <!--                     <i class="icon-attach_money"></i>-->
            <!--                  </div>-->
            <!--                  <div class="sale-num">-->
            <!--                     <h3>7500</h3>-->
            <!--                     <p>Movimentações</p>-->
            <!--                  </div>-->
            <!--               </div>-->
            <!--            </div>-->
            <!--            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">-->
            <!--               <div class="info-stats4">-->
            <!--                  <div class="info-icon">-->
            <!--                     <i class="icon-map-pin"></i>-->
            <!--                  </div>-->
            <!--                  <div class="sale-num">-->
            <!--                     <h3>3500</h3>-->
            <!--                     <p>Lotes</p>-->
            <!--                  </div>-->
            <!--               </div>-->
            <!--            </div>-->
            <!--         </div>-->
            <div class="mb-2 ml-2"><H5>Resumo de Lotes:</H5></div>
            <div class="row gutters">
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-3" v-for="iten in lots.lotsByStatus"
                     :key="iten.typeName">
                    <div class="info-stats4">
                        <div class="info-icon">
                            <i class="icon-map-pin"></i>
                        </div>
                        <div class="sale-num">
                            <h3>{{ iten.total }}</h3>
                            <p>{{ iten.typeName }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-3" v-for="iten in lots.emptyStatus"
                     :key="iten.typeName">
                    <div class="info-stats4">
                        <div class="info-icon">
                            <i class="icon-map-pin"></i>
                        </div>
                        <div class="sale-num">
                            <h3>{{ 0 }}</h3>
                            <p>{{ iten.typeName }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-3">
                    <div class="info-stats4">
                        <div class="info-icon">
                            <i class="icon-layers2"></i>
                        </div>
                        <div class="sale-num">
                            <h3>{{ lots.lots }}</h3>
                            <p>Total Lotes</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gutters">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="card">
                        <div class="p-4 row">
                            <div class="col-12 d-flex flex-row justify-content-between" style="margin-top: -0.5rem">
                                <h5 class="card-title">{{ this.t('NAV.RESERVE') }}</h5>
                            </div>
                            <div class="main-container col-12">
                                <div id="chartReserve" :style="blurLoadingR"></div>
                            </div>
                        </div>
                        <div v-if="!loadingReserve" :class="{ loader: false, fadeout: !loadingReserve}"
                             class="d-flex justify-content-center align-items-center">
                            <div class="d-flex justify-content-center align-items-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        <div class="card-body pt-0">
                            <div id="visitorsReserve"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--         <div class="row gutters">-->
            <!--            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">-->
            <!--               <div class="card">-->
            <!--                  <div class="card-header">-->
            <!--                     <div class="card-title">Vendas</div>-->
            <!--                     <div class="main-container">-->
            <!--                        <apex-charts-->
            <!--                            width="100%"-->
            <!--                            height="250px"-->
            <!--                            type="area"-->
            <!--                            :options="chartOptions"-->
            <!--                            :series="series"-->
            <!--                        ></apex-charts>-->

            <!--                     </div>-->
            <!--                  </div>-->
            <!--                  <div class="card-body pt-0">-->
            <!--                     <div id="visitors"></div>-->
            <!--                  </div>-->
            <!--               </div>-->
            <!--            </div>-->
            <!--         </div>-->
            <!--         <div class="row gutters">-->
            <!--            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">-->
            <!--               <div class="card">-->
            <!--                  <div class="card-header">-->
            <!--                     <div class="card-title">Resumo de Vendas</div>-->
            <!--                  </div>-->
            <!--                  <div class="row gutters">-->
            <!--                     <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">-->
            <!--                        <div class="p-2 m-5 rounded border border-dark text-center">-->
            <!--                           <label for="pg">Pagas</label>-->
            <!--                           <h2 id="pg">35</h2>-->
            <!--                        </div>-->
            <!--                     </div>-->
            <!--                     <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">-->
            <!--                        <div class="p-2 m-5 rounded border border-dark text-center">-->
            <!--                           <label for="at">Atrasadas</label>-->
            <!--                           <h2 id="at">25</h2>-->
            <!--                        </div>-->
            <!--                     </div>-->
            <!--                     <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">-->
            <!--                        <div class="p-2 m-5 rounded border border-dark text-center">-->
            <!--                           <label for="ab">Abertas</label>-->
            <!--                           <h2 id="ab">25</h2>-->
            <!--                        </div>-->
            <!--                     </div>-->
            <!--                     <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">-->
            <!--                        <div class="p-2 m-5 rounded border border-dark text-center">-->
            <!--                           <label for="to">Total</label>-->
            <!--                           <h2 id="to">85</h2>-->
            <!--                        </div>-->
            <!--                     </div>-->
            <!--                  </div>-->
            <!--                  <div class="card-body pt-0 pb-0">-->
            <!--                     <div id="income"></div>-->
            <!--                  </div>-->
            <!--               </div>-->
            <!--            </div>-->
            <!--         </div>-->
            <div class="mb-2 ml-2"><H5>Resumo de Reservas:</H5></div>
            <div class="row gutters">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" v-for="iten in reserves.reservesByType"
                     :key="iten.typeName">
                    <div class="info-stats4">
                        <div class="info-icon">
                            <i class="icon-lock-open"></i>
                        </div>
                        <div class="sale-num">
                            <h3>{{ iten.total }}</h3>
                            <p>{{ iten.typeName }}</p>
                        </div>
                    </div>
                </div>
                <!--            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-3" v-for="iten in reserves.reservesByStatus" :key="iten.statusName">-->
                <!--               <div class="info-stats4">-->
                <!--                  <div class="info-icon">-->
                <!--                     <i class="icon-lock-open"></i>-->
                <!--                  </div>-->
                <!--                  <div class="sale-num">-->
                <!--                     <h3>{{iten.total}}</h3>-->
                <!--                     <p>{{iten.statusName}}</p>-->
                <!--                  </div>-->
                <!--               </div>-->
                <!--            </div>-->
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                    <div class="info-stats4">
                        <div class="info-icon">
                            <i class="icon-layers2"></i>
                        </div>
                        <div class="sale-num">
                            <h3>{{ reserves.reservesTotal }}</h3>
                            <p>Total Reservas</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import PageHeader from "@/components/layouts/pageHeader";
import * as ApexChartss from "apexcharts";
import LoadScreen from "@/components/layouts/loadScreenComponent";
import Allotments from "@/services/Allotments";
import {mapState} from "vuex";
import Dashboard from "../../services/Dashboard";

export default {
    name: "DashboardAllotment",

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },

    components: {
        PageHeader,
        LoadScreen,
    },

    data() {
        return {
            totalClients: 0,
            allotments: [],
            reserves: {},
            seriesReserve: [],
            chartReserve: null,
            chartOptionsReserve: {},
            loadingReserve: true,
            lots: {},
            series: [{
                data: [5, 2, 18, 20, 10, 5, 24, 20, 25, 17, 20, 22]
            }],
            chartOptions: {
                dataLabels: {
                    enabled: true
                },
                xaxis: {
                    categories: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
                        'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                },
                colors: ['#0e5e4e'],
            }
        }
    },

    async mounted() {
        this.$store.commit('changeLoading', true);
        this.summaryLots();
        this.renderChartReserve();
        await this.summaryReserves();
        this.$store.commit('changeLoading', false);
    },

    computed: {
        ...mapState({
            isLoading: 'isLoading',
        }),
        blurLoadingR() {
            if (!this.loadingReserve) {
                return '-webkit-filter: blur(5px)';
            }
            return '';
        }
    },

    methods: {
        summaryLots() {
            Dashboard.summaryLotsByAllotment(this.$route.params.id).then(resp => {
                this.lots = resp.data;
            })
        },
        async summaryReserves() {
            await Dashboard.summaryReservesByAllotment(this.$route.params.id).then(resp => {
                this.reserves = resp.data;
            })
        },
        getClients() {
            Dashboard.getClients().then((resp) => {
                this.totalClients = resp.data.total;
            }).catch((err) => {
                console.log(err);
            })
        },

        getAllotments() {
            Allotments.list().then((resp) => {
                this.allotments = resp.data;
            }).catch((err) => {
                console.log(err);
            })
        },
        async renderChartReserve(companyId) {
            companyId = localStorage.getItem('companyId');
            Dashboard.datasAnualReserve(companyId, this.$route.params.id).then(resp => {
                if (this.chartReserve) {
                    this.chartReserve.destroy();
                }
                let result = resp.data
                this.seriesReserve = [];

                this.seriesReserve.push({
                    name: result.name1,
                    data: result.reserves
                });

                this.seriesReserve.push({
                    name: result.name2,
                    data: result.reservesConfirmed
                });

                this.chartOptionsReserve = {
                    chart: {
                        height: '280',
                        type: 'area',
                        width: '100%',
                        defaultLocale: 'pt',
                        locales: [{
                            name: 'pt',
                            options: {
                                toolbar: {
                                    download: 'Download SVG',
                                    selection: 'Selecionar',
                                    selectionZoom: 'Selecionar Zoom',
                                    zoomIn: 'Aumentar Zoom',
                                    zoomOut: 'Reduzir Zoom',
                                    pan: 'Panorama',
                                    reset: 'Resetar Zoom',
                                }
                            }
                        }],
                    },
                    noData: {
                        text: 'Nenhum dado para ser carregado',
                        align: 'center',
                        verticalAlign: 'middle',
                    },

                    series: this.seriesReserve,
                    xaxis: {
                        convertedCatToNumeric: false,
                        categories: result.categories
                    },
                    colors: ['#0e5e4e', '#0FBC9A', '#23A88D'],
                }

                let elChartReserve = document.getElementById('chartReserve');
                this.chartReserve = new ApexChartss(elChartReserve, this.chartOptionsReserve);
                this.chartReserve.render();
                this.loadingReserve = true
            }).catch((err) => {
                console.log(err);
                this.loadingReserve = true
            });
        },
    }
}
</script>

<style scoped>

</style>

import {http} from './config';

export default {
    create:(type, allotmentId) => {
        type.allotment_id = allotmentId;
        return http.post('api/v1/links', type);
    },
    delete: (id) => {
        return http.delete('api/v1/links/' + id);
    }
}

<template>
    <div>
        <page-header
            pageTitle="Cronograma"
            :btnZoom="true"
            @toggleMode="toggleMode"
            @zoom_in="zoom_in()"
            @zoom_out="zoom_out()"
            @exportToPDF='exportToPDF'
            @exportToPNG='exportToPNG'
            @exportToPNGMeadow='exportToPNGMeadow'
            @exportToPDFBroadway='exportToPDFBroadway'
            @exportToPDFSkyblue='exportToPDFSkyblue'
            @exportToPNGMaterial='exportToPNGMaterial'
        />
        <div>
            <div ref="ganttContainer" class="left-container"></div>
        </div>
        <div class="modal fade" id="addNewResponsible" tabindex="-1" role="dialog" aria-labelledby="tituloModal"
             aria-hidden="true">
            <div class="modal-dialog" role="document" style="width: 30%; max-width: none; margin-top: 17%;">
                <div class="modal-content" style="height: 300px;">
                    <div class="modal-header">
                        <h5 class="modal-title" id="tituloModal">Adicionar Encarregado</h5>
                        <button id="closeX" type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="name">Encarregado: </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                class="form-control"
                                placeholder="Nome do Encarregado"
                                v-model="responsible.name"
                            >
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                        <button type="button" class="btn btn-primary" @click="createResponsible">Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {gantt} from 'dhtmlx-gantt';
import {useToast} from 'vue-toastification';
import pageHeader from "../../components/layouts/pageHeader";
import task from "../../services/Task";
import link from "../../services/Links";
import responsiblePerTask from "../../services/ResponsiblePerTask";
import {useI18n} from "vue-i18n";

export default {
    name: 'gantt',
    components: {
        pageHeader
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    data() {
        return {
            cachedSettings: {},
            zoomConfig: {
                levels: [
                    {
                        name: "hour",
                        scale_height: 27,
                        min_column_width: 15,
                        scales: [
                            {unit: "day", format: "%d"},
                            {unit: "hour", format: "%H"},
                        ]
                    },
                    {
                        name: "day",
                        scale_height: 27,
                        min_column_width: 80,
                        scales: [
                            {unit: "day", step: 1, format: "%d %M"}
                        ]
                    },
                    {
                        name: "week",
                        scale_height: 50,
                        min_column_width: 50,
                        scales: [
                            {
                                unit: "week", step: 1, format: function (date) {
                                    const dateToStr = gantt.date.date_to_str("%d %M");
                                    const endDate = gantt.date.add(date, -6, "day");
                                    const weekNum = gantt.date.date_to_str("%W")(date);
                                    return "#" + weekNum + ", " + dateToStr(date) + " - " + dateToStr(endDate);
                                }
                            },
                            {unit: "day", step: 1, format: "%j %D"}
                        ]
                    },
                    {
                        name: "month",
                        scale_height: 50,
                        min_column_width: 120,
                        scales: [
                            {unit: "month", format: "%F, %Y"},
                            {unit: "week", format: "Week #%W"}
                        ]
                    },
                    {
                        name: "quarter",
                        height: 50,
                        min_column_width: 90,
                        scales: [
                            {
                                unit: "quarter", step: 1, format: function (date) {
                                    const dateToStr = gantt.date.date_to_str("%M");
                                    const endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                                    return dateToStr(date) + " - " + dateToStr(endDate);
                                }
                            },
                            {unit: "month", step: 1, format: "%M"},
                        ]
                    },
                    {
                        name: "year",
                        scale_height: 50,
                        min_column_width: 30,
                        scales: [
                            {unit: "year", step: 1, format: "%Y"}
                        ]
                    }
                ],
                useKey: "ctrlKey",
                trigger: "wheel",
                element: function () {
                    return gantt.$root.querySelector(".gantt_task");
                }
            },
            responsible: {},
            optionResponsible: {}
        }
    },
    mounted: function () {
        this.indexResponsiblePerTask();
        this.indexGantt();
        this.initGantt();
    },
    methods: {
        indexResponsiblePerTask() {
            this.$store.commit('changeLoading', true);
            responsiblePerTask.list(localStorage.getItem('companyId')).then(response => {
                this.$store.commit('changeLoading', false);
                this.optionResponsible = response.data.map(item => {
                    return {
                        key: item.id,
                        label: item.name
                    };
                });
                gantt.serverList("user", this.optionResponsible);
            }).catch(error => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(error)
            });
        },
        indexGantt() {
            this.$store.commit('changeLoading', true);
            this.$nextTick(() => {
                task.index(1, this.$route?.params.id, localStorage.getItem('companyId')).then(response => {
                    this.$store.commit('changeLoading', false);
                    gantt.clearAll();
                    gantt.parse(response.data.tasks);
                }).catch(error => {
                    this.$store.commit('changeLoading', false);
                    this.errorMsg(error)
                });
            });
            this.$refs.ganttContainer.scrollTo(1000, this.$refs.ganttContainer.scrollHeight);
        },
        createResponsible() {
            this.$store.commit('changeLoading', true);
            this.responsible.allotment_id = this.$route?.params.id;
            this.responsible.company_id = localStorage.getItem('companyId');
            responsiblePerTask.create(this.responsible).then(resp => {
                this.updateResponsibleList({key: resp.data.id, label: resp.data.name});
                this.$store.commit('changeLoading', false);
                document.getElementById('closeX')?.click();
                this.toast.success('Encarregado criado com sucesso!');
                this.responsible.name = ''
            }).catch(err => {
                this.$store.commit('changeLoading', false);
                this.toast.error(err.response.data.validations.errors.msg[0]);
            });
        },
        updateResponsibleList(newResponsible) {
            const userList = gantt.serverList("user");
            userList.push(newResponsible);
            gantt.updateCollection("user", userList);
            gantt.refreshData();
        },
        initGantt() {
            gantt.i18n.setLocale('pt');
            this.$nextTick(() => {
                this.configColumns();
                gantt.ext.zoom.init(this.zoomConfig);
                gantt.ext.zoom.setLevel("week");
                gantt.plugins({
                    export_api: true,
                    grouping: true,
                    tooltip: true,
                    quick_info: true
                });
                gantt.templates.tooltip_text = function (start, end, task) {
                    let data = new Date(start);
                    let ano = data.getFullYear();
                    let mes = ("0" + (data.getMonth() + 1)).slice(-2);
                    let dia = ("0" + data.getDate()).slice(-2);
                    let dataFormatada = ano + '-' + mes + '-' + dia;
                    return "<b>Tarefa:</b> " + task.text + "<br/>" +
                        "<b>Data inicio:</b> " + dataFormatada +
                        "<br/><b>Progresso:</b> " + Math.round(task.progress * 100) + "%" +
                        "<br/><b>Duração:</b> " + task.duration;
                };
                gantt.config.date_format = "%Y-%m-%d";
                this.configLightboxSections()
                gantt.config.grid_width = 680;
                gantt.init(this.$refs.ganttContainer);
            });
            this.createData();
        },
        configLightboxSections() {
            gantt.form_blocks["select_with_button"] = {
                render: function () {
                    let userListOptions = gantt.serverList("user").map(function (user) {
                        return "<option value='" + user.key + "'>" + user.label + "</option>";
                    }).join('');
                    userListOptions += "<option value='0'>Defina um Encarregado</option>";
                    return "<div style='height:30px;padding-top:0;font-size:inherit;' class='gantt_section_time'>" +
                        "<select map_to='user' style='width: 90%; height:34px;'>" +
                        userListOptions +
                        "</select>" +
                        "<button class='btn btn-primary' type='button' data-target='#addNewResponsible' data-toggle='modal'" +
                        " style='margin-left: 10px;'><i class='icon-plus'></i></button></div>";
                },
                set_value: function (node, value) {
                    const select = node.querySelector('select');
                    if (!value) {
                        select.value = "0";
                    } else {
                        select.value = value;
                    }
                },
                get_value: function (node) {
                    return node.querySelector('select').value;
                },
                focus: function (node) {
                    const select = node.querySelector('select');
                    select.focus();
                }
            };
            gantt.locale.labels.section_owner = "Encarregado";
            gantt.config.lightbox.sections = [
                {name: "description", height: 38, map_to: "text", type: "textarea", focus: true},
                {name: "owner", width: '100%', height: 38, map_to: "user", type: "select_with_button"},
                {name: "time", type: "duration", map_to: "auto"}
            ];
        },
        configColumns() {
            this.$nextTick(() => {
                gantt.config.columns = [
                    {name: "text", label: "Nome da tarefa", tree: true, width: '285'},
                    {
                        name: "owner", label: 'Encarregado', width: '168', align: "center"
                        , template: function (item) {
                            if (item.user) {
                                let list = gantt.serverList('user');
                                let user = list.find(user => user.key === item.user);
                                if (user) {
                                    return user.label || "";
                                }
                            }
                            return item.type === 'project' ? '' : 'Defina um Encarregado';
                        }
                    },
                    {
                        name: "start_date", label: "Data inicio", align: "center", width: '89',
                        template: function (item) {
                            return item.type === 'project' ? '' : item.start_date;
                        }
                    },
                    {
                        name: "duration", label: "Duração", align: "center",
                        template: function (item) {
                            return item.type === 'project' ? '' : item.duration;
                        }
                    },
                    {
                        name: "progress", label: "Progresso", align: "center",
                        template: function (item) {
                            return item.type === 'project' ? '' : Math.round(item.progress * 100) + "%";
                        }
                    },
                    {name: "add"}
                ];
            });
        },
        createData() {
            const allotmentId = this.$route?.params.id;
            const toast = this.toast;
            gantt.createDataProcessor(async (entity, action, data, id) => {
                try {
                    switch (action) {
                        case "create":
                            if (entity === 'task') {
                                await task.create(data, allotmentId);
                                toast.success('Tarefa cadastrada com sucesso');
                            } else if (entity === 'link') {
                                await link.create(data, allotmentId);
                                toast.success('Link ' + this.t('GENERAL.MSG.REGISTER_SUCCESS'));
                            }
                            break;
                        case "update":
                            await task.update(data, allotmentId);
                            toast.success('Tarefa atualizada com sucesso');
                            break;
                        case "delete":
                            if (entity === 'task') {
                                await task.delete(id);
                                toast.success('Tarefa deletada com sucesso');
                            } else if (entity === 'link') {
                                await link.delete(id);
                                toast.success('Link ' + this.t('GENERAL.MSG.DELETED_SUCCESS'));
                            }
                            break;
                    }
                } catch (error) {
                    const err = error.response.data.validations.errors.msg[0];
                    if (err === 'allotment_id notExists in company.') {
                        window.location.href = '/loteamento';
                    } else if (err !== 'allotment_id notExists in company.' && err !== '2') {
                        toast.error(err);
                    }
                }
                this.indexGantt();
            });
        },
        toggleMode(toggle) {
            this.$nextTick(() => {
                gantt.$zoomToFit = !gantt.$zoomToFit;
                if (gantt.$zoomToFit) {
                    toggle.innerHTML = "Set default Scale";
                    this.saveConfig();
                    this.zoomToFit();
                } else {
                    toggle.innerHTML = "Zoom to Fit";
                    this.restoreConfig();
                    gantt.render();
                }
                gantt.ext.zoom.setLevel("week");
            });
        },
        saveConfig() {
            const config = gantt.config;
            this.cachedSettings.scales = config.scales;
            this.cachedSettings.start_date = config.start_date;
            this.cachedSettings.end_date = config.end_date;
            this.cachedSettings.scroll_position = gantt.getScrollState();
        },
        restoreConfig() {
            this.applyConfig(this.cachedSettings);
        },
        applyConfig(config, dates) {
            gantt.config.scales = config.scales;
            const lowest_scale = config.scales.reverse()[0];
            if (dates && dates.start_date && dates.end_date) {
                gantt.config.start_date = gantt.date.add(dates.start_date, -1, lowest_scale.unit);
                gantt.config.end_date = gantt.date.add(gantt.date[lowest_scale.unit + "_start"](dates.end_date), 2, lowest_scale.unit);
            } else {
                gantt.config.start_date = gantt.config.end_date = null;
            }
            if (config.scroll_position) {
                setTimeout(function () {
                    gantt.scrollTo(config.scroll_position.x, config.scroll_position.y)
                }, 4)
            }
        },
        zoom_in() {
            gantt.ext.zoom.zoomIn();
            gantt.$zoomToFit = false;
            this.$nextTick(() => {
                const toggleElement = document.querySelector(".zoom_toggle");
                if (toggleElement) {
                    toggleElement.innerHTML = "Zoom to Fit";
                }
            });
        },
        zoom_out() {
            gantt.ext.zoom.zoomOut();
            gantt.$zoomToFit = false;
            this.$nextTick(() => {
                const toggleElement = document.querySelector(".zoom_toggle");
                if (toggleElement) {
                    toggleElement.innerHTML = "Zoom to Fit";
                }
            });
        },
        zoomToFit() {
            const project = gantt.getSubtaskDates(),
                areaWidth = gantt.$task.offsetWidth,
                scaleConfigs = this.zoomConfig.levels;
            let i;
            for (i = 0; i < scaleConfigs.length; i++) {
                let columnCount = this.getUnitsBetween(project.start_date, project.end_date, scaleConfigs[i].scales[scaleConfigs[i].scales.length - 1].unit, scaleConfigs[i].scales[0].step);
                if ((columnCount + 2) * gantt.config.min_column_width <= areaWidth) {
                    break;
                }
            }
            if (i === scaleConfigs.length) {
                i--;
            }
            gantt.ext.zoom.setLevel(scaleConfigs[i].name);
            this.applyConfig(scaleConfigs[i], project);
        },
        getUnitsBetween(from, to, unit, step) {
            let start = new Date(from),
                end = new Date(to);
            let units = 0;
            while (start.valueOf() < end.valueOf()) {
                units++;
                start = gantt.date.add(start, step, unit);
            }
            return units;
        },
        exportToPNGMaterial() {
            gantt.exportToPNG({skin: "material"});
        },
        exportToPDFSkyblue() {
            gantt.exportToPDF({skin: "skyblue"});
        },
        exportToPDFBroadway() {
            gantt.exportToPDF({skin: "broadway"});
        },
        exportToPNGMeadow() {
            gantt.exportToPNG({skin: "meadow"});
        },
        exportToPNG() {
            gantt.exportToPNG();
        },
        exportToPDF() {
            gantt.exportToPDF();
        }
    }
}
</script>

<style scoped>
@import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";
@import "https://docs.dhtmlx.com/gantt/samples/common/controls_styles.css";
@import "https://docs.dhtmlx.com/gantt/codebase/dhtmlxgantt.css";

.left-container {
    position: relative;
    height: 75vh;
    width: 100%;
    border-radius: 6px;
}

.modal {
    z-index: 10002;
}
</style>


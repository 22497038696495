import {http} from './config';

export default {
    create:(type, allotmentId) => {
        type.allotment_id = allotmentId;
        return http.post('api/v1/tasks', type);
    },
    index:(page=1, allotmentId ,companyId) => {
        return http.get('api/v1/tasks?page=' + page, {headers: {'allotment-id': allotmentId ,'company-id': companyId}});
    },
    update:(type,allotmentId) => {
        type.allotment_id = allotmentId;
        return http.put('api/v1/tasks/' + type.id, type);
    },
    delete: (id) => {
        return http.delete('api/v1/tasks/' + id);
    }
}
